<template>
  <div class="alert-wrapper" v-if="showTimedAlert">
    <div class="overlay"></div>
    <transition-group tag="div" class="alerts" name="slide-fade">
      <div class="header-alert" key="notf-header">
        <nuxt-img
          quality="90"
          format="webp"
          placeholder
          src="/icons/icons/shots-icon-white.svg"
          alt="small logo"
        />
      </div>
      <div key="al-con" class="alert-content">
        <div class="alert-text">
          <h3 class="title font-mont text-red-title">
            {{ $t("Exciting things are coming") }}
          </h3>
          <p class="font-fgb subtitle">
            {{
              $t("On 01-01-2024 we will start the New Year with a new website")
            }}
          </p>
          <p class="font-fgb text">
            {{
              $t(
                `Don't just hold your breath, but hold your order too until the launch comes! Thank you and a Happy New Year!`
              )
            }}
          </p>
        </div>
        <div class="logo">
          <nuxt-img
            quality="90"
            format="webp"
            placeholder
            src="/icons/icons/shots-icon-white.svg"
            alt="small logo"
          />
        </div>
      </div>
    </transition-group>
  </div>
</template>
<script>
import { computed, onMounted, onBeforeUnmount } from "@nuxtjs/composition-api";
import { useConfigStore } from "~/stores/config";

export default {
  name: "TimedAlert",
  setup() {
    const configStore = useConfigStore();
    const showTimedAlert = computed(() => configStore.showLunchPopUp);

    // Set showTimedAlert to false initially before mounting
    onMounted(() => {
      if (process.client && showTimedAlert.value) {
        document.body.style.overflow = "hidden";
      }
    });

    // Cleanup when the component is about to be removed
    onBeforeUnmount(() => {
      if (process.client) {
        document.body.style.overflow = ""; // Reset to default
      }
    });

    return {
      showTimedAlert,
    };
  },
};
</script>
<style scoped lang="scss">
.alert-wrapper {
  .overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 4;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    background: #909090 0% 0% no-repeat padding-box;
    mix-blend-mode: multiply;
    overscroll-behavior: contain;
  }
}
.alerts {
  @apply bg-white relative opacity-100;
  border-radius: 1.2rem;
  min-height: 200px;
  background-image: url("@/assets/images/Intersection.svg");
  background-repeat: no-repeat;
  background-position: 0px bottom;
  background-size: contain;
  position: fixed;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
  z-index: 5;
  width: 500px;
  @media only screen and (max-width: 480px) {
    width: 340px;
  }
  .header-alert {
    @apply flex justify-center py-2;
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
    background: rgb(227, 6, 19);
    background: linear-gradient(
      90deg,
      rgba(227, 6, 19, 1) 0%,
      rgba(167, 3, 3, 1) 68%
    );
    img {
      width: 45px;
      height: auto;
      transform: rotate(-10deg);
    }
    .remove-icon {
      @apply absolute text-white text-2xl cursor-pointer;
      top: 5px;
      right: 10px;
      transition: all 100ms ease-in;
      &:active {
        transform: scale(0.9);
      }
    }
  }
  .alert-content {
    @apply flex justify-center items-center text-center py-8 px-10 font-bold font-mont;
  }
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s;
  @include for-desktop {
    transition: opacity 0.25s linear;
  }
}

.slide-fade-enter {
  transform: translateY(40px);
  @include for-desktop {
    opacity: 0;
  }
}

.slide-fade-leave-to {
  transform: translateY(80px);
  @include for-desktop {
    opacity: 0;
  }
}
.title {
  font-size: 45px;
  line-height: 45px;
}
.alert-text {
  .subtitle {
    max-width: 220px;
    width: 100%;
    margin: 30px auto;
  }
  .text {
    font-weight: 400;
    max-width: 350px;
    margin: 0 auto 30px auto;
  }
}
.logo {
  position: absolute;
  bottom: 25px;
  right: 30px;
  height: 50px;
  width: 50px;
  background-color: rgb(232 232 232 / var(--tw-bg-opacity));
  padding: 10px;
  border-radius: 25px;
  transform: rotate(-10deg);
  @media only screen and (max-width: 480px) {
    bottom: 10px;
  }
}
</style>
